// import { Document } from '~/document';
// import { captureRemixErrorBoundaryError, withSentry } from '@sentry/remix';
import { useState, useEffect, lazy, Suspense } from 'react';
import { withSentry } from '@sentry/remix';
import type { LinksFunction } from '@remix-run/node';
import { useTheme } from '@mui/material/styles';
import {
	Scripts,
	useSearchParams,
	ScrollRestoration,
	PrefetchPageLinks,
	useLoaderData,
	useActionData,
	Links,
	LiveReload,
	Meta,
	Outlet,
	useLocation,
	useFetcher,
	useRouteError,
} from '@remix-run/react';
import { initPlausible } from '~/utils/plausible';
import { Hydrate, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { useChangeLanguage } from 'remix-i18next/react';

import { Page } from '~/content/components/Page';
import { Main } from '~/content/layouts/Main/Main';
import { useTranslation } from 'react-i18next';
// import NotFound from './views/notFound';
// import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import clientCheck from '~/utils/clientCheck';
import { LicenseInfo } from '@mui/x-date-pickers-pro';
LicenseInfo.setLicenseKey(
	'a68035d2c1f75233467070056352245cT1JERVI6Mzc1NTQsRVhQSVJZPTE2NzYwNDUzOTgwMDAsS0VZVkVSU0lPTj0x',
);

import styles from '~/styles/index.css?url';
export const links: LinksFunction = () => {
	return [
		{ rel: 'preload', href: styles, as: 'style' },
		{ rel: 'stylesheet', href: styles, as: 'style' },
		// { rel: 'preload', href: '/fonts/AllianceNo1-Regular.woff2', as: 'font', type: 'font/woff2', crossOrigin: 'anonymous' },
		// { rel: 'preload', href: '/fonts/AllianceNo1-Bold.woff2', as: 'font', type: 'font/woff2', crossOrigin: 'anonymous' },
		// { rel: 'preload', href: '/fonts/AllianceNo2-Regular.woff2', as: 'font', type: 'font/woff2', crossOrigin: 'anonymous' },
		// { rel: 'preload', href: '/fonts/AllianceNo2-SemiBold.woff2', as: 'font', type: 'font/woff2', crossOrigin: 'anonymous' },
	];
};
import { externalLoader, externalAction } from '~/utils/externalLoader.server';
export const loader = externalLoader;
export const action = externalAction;
export let handle = {
	i18n: 'common',
};
const CCBlock = lazy(async () => ({
	default: (await import('~/content/components/CCBlock')).CCBlock,
}));

// const CookieConsent = lazy(async () => {
// 	// default: (await import('~/content/components/CookieConsent')).CookieConsent,
// 	try {
// 		return {
// 			default: (await import('~/content/components/CookieConsent')).CookieConsent,
// 		};
// 	} catch (error) {
// 		//* fallback if the component is blocked by an ad blocker
// 		return {
// 			default: () => {
// 				const theme = useTheme();
// 				return (
// 					<div
// 						style={{
// 							position: 'fixed',
// 							zIndex: 9999,
// 							bottom: 0,
// 							fontSize: '0.8rem',
// 							width: '100%',
// 							padding: '5px',
// 							backgroundColor: theme.palette.background.level2,
// 							textAlign: 'center',
// 						}}
// 					>
// 						Your AdBlocker is blocking important components. Please disable it to enhance your
// 						experience.
// 					</div>
// 				);
// 			},
// 		};
// 	}
// });
function App() {
	const loaderData = useLoaderData<typeof loader>();
	//* adding global ENV variable to client window
	if (clientCheck() && ENV_MODE !== 'development') {
		// window.ENV = .ENV as any;
		//@ts-ignore
		initPlausible(ENV_MODE);
	}
	let [searchParams, setSearchParams] = useSearchParams();
	const noQueryIsSet = searchParams.toString() === '';
	const [stagingMode, setStagingMode] = useState<boolean>(false);

	const [queryClient] = useState(() => new QueryClient());
	try {
		//@ts-ignore
		var { userLanguage } = useLoaderData();
	} catch (error) {
		var userLanguage = 'de';
	}
	let { i18n } = useTranslation();
	let fetcher = useFetcher({ key: 'languageToggle' });
	let html_lang = userLanguage;
	if (fetcher.formData?.has('userLanguage')) {
		// console.log('fetcher.formData?.get(userLanguage)', fetcher.formData?.get('userLanguage'));
		useChangeLanguage(fetcher.formData?.get('userLanguage') as string);
		if (fetcher.formData?.get('userLanguage') === 'de') {
			html_lang = 'de-DE';
		}
	} else {
		useChangeLanguage(userLanguage);
		if (userLanguage === 'de') {
			html_lang = 'de-DE';
		}
	}

	let location = useLocation();
	let location_without_lang = location.pathname.replace(/^\/[a-z]{2}\//, '/');
	if (location.pathname === '/de' || location.pathname === '/en') location_without_lang = ''; //* remove language from root
	const en_URL = 'https://northrock.software/en' + location_without_lang;
	const de_URL = 'https://northrock.software' + location_without_lang;
	// console.log({ loaderData });
	// console.log(typeof loaderData.enableTracking);
	// console.log('-------------reloadPage', loaderData.reloadPage);
	//* reload curent page if reloadPage is true - but only on client and only once
	// let reloadPage = false;
	// if (clientCheck() && loaderData.reloadPage && !reloadPage) {
	//   reloadPage = true;
	//   console.log('reloading page');
	//   // navigate(location.pathname);
	//   // window.location.reload();
	// }
	useEffect(() => {
		if (ENV_MODE === 'staging') {
			setStagingMode(true);
		}
	}, []);
	return (
		// <Document themeMode={loaderData.themeMode} enableTracking={loaderData.enableTracking}>
		<html lang={html_lang} dir={i18n.dir()}>
			{/* <!-- -- NorthRock software GmbH -- @version 1.0 -- @date 20.11.2023 -- @url https://northrok.software --> */}

			<head>
				<meta charSet='utf-8' />
				<meta httpEquiv='Content-Type' content='text/html;charset=utf-8' />
				<meta httpEquiv='Content-Security-Policy' content='upgrade-insecure-requests' />
				{/* <meta name='viewport' content='width=device-width, initial-scale=1, shrink-to-fit=no' /> */}
				<meta name='viewport' content='width=device-width, initial-scale=1, maximum-scale=1' />
				<meta name='application-name' content='NorthRock' />

				<link rel='shortcut icon' href='https://northrock.software/meta/icons/favicon.ico' />
				<link
					rel='icon'
					type='image/x-icon'
					href='https://northrock.software/meta/icons/favicon.ico'
				/>
				<link
					rel='icon'
					type='image/png'
					href='https://northrock.software/meta/icons/android-chrome-512x512.png'
				/>

				<meta name='theme-color' content='#ffffff' media='(prefers-color-scheme: light)' />
				<meta name='theme-color' content='#000000' media='(prefers-color-scheme: dark)' />

				<meta
					name='robots'
					content={ENV_MODE === 'production' ? 'index, follow' : 'noindex, nofollow'}
				/>
				<meta
					name='googlebot'
					content={ENV_MODE === 'production' ? 'index, follow' : 'noindex, nofollow'}
				/>
				{/* {!prod_env && <meta name='robots' content='noindex,nofollow' />} */}
				<meta name='google' content='notranslate' />
				<meta name='format-detection' content='telephone=no' />

				<meta name='author' content='NorthRock software GmbH' />
				<meta name='copyright' content='NorthRock software GmbH' />

				<meta name='thumbnail' content='https://northrock.software/meta/thumbnailSquare.png' />

				<meta property='og:site_name' content='NorthRock software GmbH' />
				<meta property='og:image' content='https://northrock.software/meta/thumbnail.png' />
				<meta
					property='og:image:secure_url'
					content='https://northrock.software/meta/thumbnail.png'
				/>
				<meta
					property='og:image:alt'
					content='An arrowhead like logo in black with a white background'
				/>
				<meta property='og:image:width' content='1200' />
				<meta property='og:image:height' content='630' />
				<meta property='og:type' content='websites' />

				<meta name='twitter:title' content='NorthRock software GmbH' />
				<meta name='twitter:card' content='summary_large_image' />
				<meta name='twitter:image' content='https://northrock.software/meta/thumbnail2to1.png' />

				<meta name='apple-mobile-web-app-title' content='NorthRock' />
				<link
					rel='apple-touch-icon'
					sizes='180x180'
					href='https://northrock.software/meta/icons/apple-touch-icon.png'
				/>
				<meta name='mobile-web-app-capable' content='yes' />
				<meta name='apple-mobile-web-app-capable' content='yes' />
				<meta name='apple-mobile-web-app-status-bar-style' content='default' />

				{/* <meta name="theme-color" content="#ecd96f"  media="(prefers-color-scheme: light)" />
				<meta name="theme-color" content="#0b3e05"  media="(prefers-color-scheme: dark)" /> */}

				<meta property='og:locale' content={userLanguage === 'de' ? 'de_DE' : 'en_US'} />
				<meta property='og:locale:alternate' content={userLanguage === 'de' ? 'en_US' : 'de_DE'} />
				{/* <link rel='alternate' hrefLang='de-de' href={de_URL} /> */}
				{/* <link rel='alternate' hrefLang='en' href={en_URL} /> */}
				{noQueryIsSet && <link rel='alternate' hrefLang='de-de' href={de_URL} />}
				{noQueryIsSet && <link rel='alternate' hrefLang='en' href={en_URL} />}
				{/* <link rel='alternate' hrefLang='x-default' href='https://northrock.software' /> */}
				<link rel='canonical' href={'https://northrock.software' + location.pathname} />

				{/* <script defer data-domain='northrock.software' src='https://tracking.northrock.systems/js/script.js'></script> */}
				{/* {enableTracking && <script async src='/newrelic/agent-production.js'></script>} */}
				<Meta />
				<Links />
			</head>
			<body>
				<QueryClientProvider client={queryClient}>
					<Page userPref={loaderData.themeMode}>
						<Suspense fallback={<></>}>
							<CCBlock />
						</Suspense>
						<Main>
							{stagingMode && (
								<div
									style={{
										position: 'fixed',
										width: '100%',
										height: '100%',
										zIndex: 9999,
										display: 'flex',
										justifyContent: 'center',
										alignItems: 'center',
										// color: 'text.secondary',
										mixBlendMode: 'difference',
										color: 'white',
										fontSize: '2rem',
										pointerEvents: 'none',
										transform: 'rotate(-25deg)',
									}}
								>
									STAGING!
								</div>
							)}
							<Outlet />
						</Main>
					</Page>
				</QueryClientProvider>
				<ScrollRestoration />
				<Scripts />
				{/* {isBot ? null : <Scripts />} */}
			</body>
		</html>
	);
}

// export const shouldRevalidate: ShouldRevalidateFunction = ({
//   actionResult,
//   currentParams,
//   currentUrl,
//   defaultShouldRevalidate,
//   formAction,
//   formData,
//   formEncType,
//   formMethod,
//   nextParams,
//   nextUrl,
// }) => {
//   console.log({ actionResult });
//   console.log({ currentUrl });
//   return true;
// };
// export const shouldRevalidate = () => false;

// import { useRouteLoaderData } from '@remix-run/react';
// import { getSession, commitSession } from '~/utils/sessions';

// export const loader = async ({ request }: { request: Request }) => {
//   console.log('in cookie loader');
//   // Read session from cookie
//   const session = await getSession(request.headers.get('Cookie'));

//   // Increment by 1 if exists, otherwise set to 1
//   const numberOfVisits: number = session.get('numberOfVisits') + 1 || 1;

//   // Create new cookie string
//   session.set('numberOfVisits', numberOfVisits);
//   const cookie = await commitSession(session);

//   // Set new cookie in headers
//   return json(
//     { numberOfVisits },
//     {
//       headers: {
//         'Set-Cookie': cookie,
//       },
//     },
//   );
// };

// import { getSession, commitSession } from '~/utils/sessions';
// export const loader = async ({ request }: { request: Request }) => {
//   // Read session from cookie
//   const session = await getSession(request.headers.get('Cookie'));

//   // Increment by 1 if exists, otherwise set to 1
//   const numberOfVisits: number = session.get('numberOfVisits') + 1 || 1;

//   // Create new cookie string
//   session.set('numberOfVisits', numberOfVisits);
//   const cookie = await commitSession(session);

//   // Set new cookie in headers
//   return json(
//     { numberOfVisits },
//     {
//       headers: {
//         'Set-Cookie': cookie,
//       },
//     },
//   );
// };

// export const loader = async ({ request }: LoaderArgs) => {
//   console.log('loader');
//   // Get the session from the cookie
//   const session = await getSession(request.headers.get('Cookie'));
//   const myStoredData = session.get('myStoredData');
//   // If no session found (was never created or was expired) create a new session.
//   if (!myStoredData) {
//     session.set('myStoredData', 'Some data');
//     return json({ message: 'Created new session' }, { headers: { 'Set-Cookie': await commitSession(session) } });
//   }
//   // If session was found, present the session info.
//   return json({
//     message: `Showing Session info: ${myStoredData}`,
//   });
// };
// export default App;

// export default withSentry(App);
export default withSentry(App, {
	errorBoundaryOptions: {
		fallback: <p>Something went wrong</p>,
	},
});
// function App() {
// 	return <Outlet />;
// }
// export default withSentry(App, {
// 	errorBoundaryOptions: {
// 		fallback: <p>Something went wrong</p>,
// 	},
// });
// export function CatchBoundary() {
// export const CatchBoundary = () => {
//   const caught = useCatch();
//   return (
//     <Document title={`${caught.status} - ${caught.statusText}`} doNOTcrawl={true}>
//       <NotFound />
//     </Document>
//   );
// };

// export function ErrorBoundary({ error }: { error: Error }) {
// 	// export function ErrorBoundary( { error }: Error) {
// 	const errorBoundry: any = useRouteError();
// 	captureRemixErrorBoundaryError(error);
// 	// export const ErrorBoundary = ({ error }: { error: Error }) => {
// 	return (
// 		<Document>
// 			<div className='error-container'>
// 				<h1>App Error</h1>
// 				<pre>{errorBoundry.message}</pre>
// 			</div>
// 		</Document>
// 	);
// }
